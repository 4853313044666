<script lang="ts" setup>
const inProduction = useProduction();
</script>

<template>
    <footer class="bg-blue-900 max-w-screen-3xl m-auto">
        <section>
            <div
                class="section-content flex flex-col justify-between gap-8 py-8 text-white text-[0.75rem] lg:text-[0.875rem] lg:flex-row lg:py-16 lg:items-start"
            >
                <div class="flex justify-center items-center flex-col lg:flex-row lg:gap-7">
                    <NuxtLink to="/">
                        <picture>
                            <source media="(max-width: 1024px)" srcset="/images/vetify/vetify_logo_negativo-sm.svg" />
                            <img
                                class="w-[10rem] lg:w-[14rem]"
                                src="/images/vetify/vetify_logo_negativo-lg.svg"
                                alt="vetify_logo"
                            />
                        </picture>
                    </NuxtLink>
                    <span class="-mt-4 opacity-70 lg:mt-0">Soluções veterinárias, Lda</span>
                </div>
                <div class="flex justify-center items-center flex-col">
                    <nav class="text-center font-bold lg:text-left">
                        <ul class="lg:flex lg:gap-16">
                            <div>
                                <li class="w-full">
                                    <NuxtLink to="/">Marcas</NuxtLink>
                                </li>
                                <li v-if="!inProduction" class="w-full">
                                    <NuxtLink to="/products/category">Produtos</NuxtLink>
                                </li>
                            </div>
                            <div>
                                <li class="w-full">
                                    <NuxtLink to="/reseller">Revendedores</NuxtLink>
                                </li>
                                <li v-if="!inProduction" class="w-full">
                                    <NuxtLink to="/events">Eventos</NuxtLink>
                                </li>
                                <li class="w-full">
                                    <NuxtLink to="/contacts">Contactos</NuxtLink>
                                </li>
                            </div>
                        </ul>
                    </nav>
                </div>
                <div class="flex justify-center items-center flex-col lg:items-start lg:justify-start leading-normal">
                    <p>Rua dos Enganos, 1, 8º Andar</p>
                    <p>Luanda, Angola</p>
                    <p>comercial@vetify.co.ao</p>
                </div>
            </div>
        </section>
    </footer>
</template>

<style lang="scss" scoped>
nav ul div {
    a {
        @apply text-white;
    }

    li {
        @apply mt-2 lg:mt-0;
    }
}
</style>
