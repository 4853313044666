<script lang="ts" setup>
import { CATEGORIES } from "~/lib/data";
const isMenuVisible = ref(false);
const inProduction = useProduction();
const auth = useAuth();

function toggleMenuVisibility() {
    isMenuVisible.value = !isMenuVisible.value;
}
</script>

<template>
    <header
        class="absolute top-0 left-0 w-full py-[1.625rem] z-[999] lg:left-1/2 lg:-translate-x-1/2 lg:bg-transparent"
        :class="{ 'position-fixed': isMenuVisible }"
    >
        <section>
            <div class="section-content flex justify-between items-center">
                <NuxtLink to="/" class="w-[7.5rem] lg:w-44 lg:h">
                    <img class="w-full h-full" src="/images/vetify/vetify_logo.svg" alt="Logo da Vetify" />
                </NuxtLink>

                <div>
                    <span
                        @click="toggleMenuVisibility"
                        class="material-symbols-rounded text-[2.5rem] text-primary cursor-pointer lg:hidden"
                        >menu</span
                    >

                    <div
                        class="header__menu flex justify-between flex-col fixed top-[3.875rem] left-0 w-full min-h-screen text-primary bg-white py-8 lg:relative lg:top-0 lg:left-0 lg:justify-end lg:h-auto lg:bg-transparent lg:min-h-0"
                        :class="{hidden: !isMenuVisible}"
                    >
                        <nav class="flex-1 flex justify-center">
                            <ul
                                class="flex justify-center items-center flex-col gap-5 lg:gap-6 2xl:gap-8 lg:flex lg:flex-row lg:flex-1"
                            >
                                <li>
                                    <NuxtLink to="/#brands">Marcas</NuxtLink>
                                </li>
                                <li v-if="!inProduction">
                                    <NuxtLink to="/products/category">Produtos</NuxtLink>
                                </li>

                                <ul
                                    class="flex justify-center items-center flex-col mb-8 gap-5 lg:hidden"
                                    v-if="!inProduction"
                                >
                                    <li v-for="(category, idx) in CATEGORIES" :key="idx">
                                        <NuxtLink :to="category.route">{{ category.title }}</NuxtLink>
                                    </li>
                                </ul>

                                <li>
                                    <NuxtLink to="/reseller">Revendedores</NuxtLink>
                                </li>
                                <li v-if="!inProduction">
                                    <NuxtLink to="/events">Eventos</NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink to="/contacts">Contactos</NuxtLink>
                                </li>
                                <li  v-if="!inProduction" class="flex justify-center items-center">
                                    <NuxtLink to="/order">
                                        <Icon
                                            :name="auth.isLoggedIn ? 'ic:baseline-shopping-cart' : 'mdi:lock-outline'"
                                            size="20px"
                                        />
                                    </NuxtLink>
                                </li>
                            </ul>
                        </nav>

                        <div class="flex flex-col items-center text-base mb-8 lg:hidden">
                            <p>Rua dos Enganos, 1, 8º Andar</p>
                            <p>Luanda, Angola</p>
                            <p>comercial@vetify.co.ao</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </header>
</template>

<style lang="scss" scoped>
header {
    &.position-fixed {
        @apply fixed bg-white lg:bg-transparent lg:block;
    }
    .material-symbols-rounded {
        font-variation-settings: "wght" 600;
    }
    .header__menu {
        nav ul {
            li {
                @apply font-bold text-[1.375rem];

                a.router-link-exact-active {
                    @apply text-primary;
                }
            }

            ul li {
                @apply font-normal;
            }
        }

        &.hidden {
            display: none;
        }
    }
}

@media (min-width: 1024px) {
    header div.header__menu {
        &.hidden {
            display: flex;
        }
        nav ul li a {
            @apply text-[1.125rem] text-blue-900;
        }
    }
}
</style>
